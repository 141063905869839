import { Link } from "gatsby";
import React from "react";

import Layout from "src/components/layout";

const IndexPage = () => (
  <Layout>
    <div className="text-9xl font-semibold text-gray-300 text-center">404</div>
    <div className="text-xl text-center">The page you are looking doesn't exist. Page try a different one.</div>
    <div className="flex justify-center">
      <div className="cursor-pointer px-9 py-2 mt-4 rounded-full shadow-lg from-blue-500 to-blue-400 bg-gradient-to-r text-xl font-medium text-white">
        <Link to="/">Go to home page</Link>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
